import React, { useState, useRef } from 'react';
import html2canvas from 'html2canvas';
import logo from './assets/kilroy.jpeg';
import body from './assets/body.png';
import face from './assets/face.png';
import './AppMobile.css';

function AppMobile() {

    const traitNames = ["CLOTHES", "ACCESSORIES", "FACES", "HEADS", "BACKGROUNDS"];

    const images = [
        [
            `${process.env.PUBLIC_URL}/assets/layers/none.png`,
            `${process.env.PUBLIC_URL}/assets/layers/clothes/1.png`,
            `${process.env.PUBLIC_URL}/assets/layers/clothes/2.png`,
            `${process.env.PUBLIC_URL}/assets/layers/clothes/3.png`,
            `${process.env.PUBLIC_URL}/assets/layers/clothes/4.png`,
            `${process.env.PUBLIC_URL}/assets/layers/clothes/5.png`,
            `${process.env.PUBLIC_URL}/assets/layers/clothes/6.png`,
            `${process.env.PUBLIC_URL}/assets/layers/clothes/7.png`,
            `${process.env.PUBLIC_URL}/assets/layers/clothes/8.png`,
            `${process.env.PUBLIC_URL}/assets/layers/clothes/9.png`,
            `${process.env.PUBLIC_URL}/assets/layers/clothes/10.png`,
            `${process.env.PUBLIC_URL}/assets/layers/clothes/11.png`,
            `${process.env.PUBLIC_URL}/assets/layers/clothes/12.png`,
            `${process.env.PUBLIC_URL}/assets/layers/clothes/13.png`,
            `${process.env.PUBLIC_URL}/assets/layers/clothes/14.png`

        ],
        [
            `${process.env.PUBLIC_URL}/assets/layers/none.png`,
            `${process.env.PUBLIC_URL}/assets/layers/accessories/1.png`,
            `${process.env.PUBLIC_URL}/assets/layers/accessories/2.png`,
            `${process.env.PUBLIC_URL}/assets/layers/accessories/3.png`,
            `${process.env.PUBLIC_URL}/assets/layers/accessories/4.png`,
            `${process.env.PUBLIC_URL}/assets/layers/accessories/5.png`,
            `${process.env.PUBLIC_URL}/assets/layers/accessories/6.png`,
            `${process.env.PUBLIC_URL}/assets/layers/accessories/7.png`,
            `${process.env.PUBLIC_URL}/assets/layers/accessories/8.png`,
            `${process.env.PUBLIC_URL}/assets/layers/accessories/9.png`,
            `${process.env.PUBLIC_URL}/assets/layers/accessories/10.png`
        ],
        [
            `${process.env.PUBLIC_URL}/assets/layers/none.png`,
            `${process.env.PUBLIC_URL}/assets/layers/face/1.png`,
            `${process.env.PUBLIC_URL}/assets/layers/face/2.png`,
            `${process.env.PUBLIC_URL}/assets/layers/face/3.png`,
            `${process.env.PUBLIC_URL}/assets/layers/face/4.png`,
            `${process.env.PUBLIC_URL}/assets/layers/face/5.png`,
            `${process.env.PUBLIC_URL}/assets/layers/face/6.png`,
            `${process.env.PUBLIC_URL}/assets/layers/face/7.png`,
            `${process.env.PUBLIC_URL}/assets/layers/face/8.png`
        ],
        [
            `${process.env.PUBLIC_URL}/assets/layers/none.png`,
            `${process.env.PUBLIC_URL}/assets/layers/heads/1.png`,
            `${process.env.PUBLIC_URL}/assets/layers/heads/2.png`,
            `${process.env.PUBLIC_URL}/assets/layers/heads/3.png`,
            `${process.env.PUBLIC_URL}/assets/layers/heads/4.png`,
            `${process.env.PUBLIC_URL}/assets/layers/heads/5.png`,
            `${process.env.PUBLIC_URL}/assets/layers/heads/6.png`,
            `${process.env.PUBLIC_URL}/assets/layers/heads/7.png`,
            `${process.env.PUBLIC_URL}/assets/layers/heads/8.png`,
            `${process.env.PUBLIC_URL}/assets/layers/heads/9.png`,
            `${process.env.PUBLIC_URL}/assets/layers/heads/10.png`,
            `${process.env.PUBLIC_URL}/assets/layers/heads/11.png`
        ],
        [
            `${process.env.PUBLIC_URL}/assets/layers/none.png`,
            `${process.env.PUBLIC_URL}/assets/layers/backgrounds/1.png`,
            `${process.env.PUBLIC_URL}/assets/layers/backgrounds/2.png`,
            `${process.env.PUBLIC_URL}/assets/layers/backgrounds/3.png`,
            `${process.env.PUBLIC_URL}/assets/layers/backgrounds/4.png`,
            `${process.env.PUBLIC_URL}/assets/layers/backgrounds/5.png`
        ]
    ];

    const [selectedIndices, setSelectedIndices] = useState<number[]>(Array(images.length).fill(0));

    const containerRefs = useRef<(HTMLDivElement | null)[]>([]);

    const scrollLeft = (index: number) => {
        const container = containerRefs.current[index];
        if (container) {
            container.scrollBy({
                top: 0,
                left: -180,
                behavior: 'smooth'
            });
        }
    };

    const scrollRight = (index: number) => {
        const container = containerRefs.current[index];
        if (container) {
            container.scrollBy({
                top: 0,
                left: 180,
                behavior: 'smooth'
            });
        }
    };

    const resetImage = () => {
        const newSelectedIndices = [...selectedIndices];
        for (let i = 0; i < newSelectedIndices.length; i++) {
            newSelectedIndices[i] = 0;
        }
        setSelectedIndices(newSelectedIndices);
    }

    const randomizeImage = () => {
        const newSelectedIndices = [...selectedIndices];
        for (let i = 0; i < newSelectedIndices.length; i++) {
            newSelectedIndices[i] = Math.floor(Math.random() * images[i].length);
        }
        setSelectedIndices(newSelectedIndices);
    }

    const downloadImage = () => {
        const container = document.querySelector('.image-container') as HTMLElement;
        if (container) {
            html2canvas(container).then(canvas => {
                const link = document.createElement('a');
                link.href = canvas.toDataURL('image/png');
                link.download = 'meme.png';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
        }
    };

    const handleImageClick = (layerIndex: number, imageIndex: number) => {
        const newSelectedIndices = [...selectedIndices];
        newSelectedIndices[layerIndex] = imageIndex;
        setSelectedIndices(newSelectedIndices);
    };

    return (
        <div className="main-container-mobile">
            <div className="nav-strip-mobile">
                <img className="nav-logo-mobile" src={logo} alt=""/>
                <a className="nav-link-mobile" href="https://www.kilroysolana.com/" target="_">
                    <h2 className="nav-text-mobile">KILROY</h2>
                </a>
            </div>
            <div className="title-container-mobile">
                <h1 className="title-mobile">KILROY <br/> MEME MAKER</h1>
            </div>
            <div className="body-container-mobile">
                <div className="meme-container-mobile">
                    <div className="image-border-mobile">
                        <div className="image-container-mobile">
                            {selectedIndices[4] === 0 ? (
                                <div/>
                            ) : (
                                <img className="image-mobile layer-zero-mobile" src={images[4][selectedIndices[4]]} alt=""/>
                            )}
                            <img className="image-mobile layer-one-mobile" src={body} alt=""/>
                            {selectedIndices[0] === 0 ? (
                                <div/>
                            ) : (
                                <img className="image-mobile layer-two-mobile" src={images[0][selectedIndices[0]]} alt=""/>
                            )}
                            {selectedIndices[1] === 0 ? (
                                <div/>
                            ) : (
                                <img className="image-mobile layer-three-mobile" src={images[1][selectedIndices[1]]} alt=""/>
                            )}
                            {selectedIndices[2] === 0 ? (
                                <img className="image-mobile layer-four-mobile" src={face} alt=""/>
                            ) : (
                                <img className="image-mobile layer-four-mobile" src={images[2][selectedIndices[2]]} alt=""/>
                            )}
                            {selectedIndices[3] === 0 ? (
                                <div/>
                            ) : (
                                <img className="image-mobile layer-five-mobile" src={images[3][selectedIndices[3]]} alt=""/>
                            )}
                        </div>
                    </div>
                    <button className="button-one-mobile" onClick={resetImage}> RESET KILROY </button>
                    <button className="button-one-mobile" onClick={randomizeImage}> GENERATE RANDOM </button>
                    <button className="button-two-mobile" onClick={downloadImage}> DOWNLOAD </button>
                </div>
                <div className="traits-container-mobile">
                    {images.map((layer, layerIndex) => (
                        <div>
                            <h2 className="trait-category-header-mobile">{traitNames[layerIndex]}</h2>
                            <div className="scroll-panel-mobile" key={layerIndex}>
                                <button className="scroll-button-mobile" onClick={() => scrollLeft(layerIndex)}> {"<"} </button>
                                <div
                                    className="trait-image-container-mobile"
                                    ref={el => containerRefs.current[layerIndex] = el}
                                >
                                    {layer.map((image, imageIndex) => (
                                        <div className={`trait-image-border-mobile ${selectedIndices[layerIndex] === imageIndex ? 'trait-image-border-selected-mobile' : ''}`}>
                                            <img
                                                className={`trait-image-mobile ${selectedIndices[layerIndex] === imageIndex ? 'trait-image-selected-mobile' : ''}`}
                                                src={image}
                                                alt=""
                                                key={imageIndex}
                                                onClick={() => handleImageClick(layerIndex, imageIndex)}
                                            />
                                        </div>
                                    ))}
                                </div>
                                <button className="scroll-button-mobile" onClick={() => scrollRight(layerIndex)}> {">"} </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default AppMobile;
