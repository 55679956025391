import React from 'react';
import AppDesktop from "./AppDesktop";
import AppMobile from "./AppMobile";

function App() {

    function isMobile() {
        // @ts-ignore
        const match = window.matchMedia || window.msMatchMedia;
        if(match) {
            const mq = match("(pointer:coarse)");
            return mq.matches;
        }
        return false;
    }


    if (isMobile()) {
        return (<AppMobile/>);
    } else {
        return (<AppDesktop/>);
    }
}

export default App;
